import React from 'react';
import './Header.css';

const Header = () => {
    return (
      
        <div className="container row">
            {/* <a href="#"><li>Home</li></a>
            <a href="#"><li>About</li></a> */}
            <div id="app-title">
              <h1 id="g">G</h1>
              <h1 id="rammar">rammar</h1>
              <h1 id="hun">hun</h1>
              <h1 id="t">T</h1>
            </div>
            <div id="styled-header-div-b"></div>
            <div id="styled-header-div-a"></div>
        </div>
       
      
    )
  }    

export default Header;