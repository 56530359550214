import React from 'react';
import axios from 'axios';
import './ApiQuery.css';

class ApiQuery extends React.Component {
 
    state = {
        word: '',
        url: '',
        data: [],
        returnedWord: '',
        wordError: '',
        audioPrefix: '',
        audio: '',
        audioError: '',
        extension: '.wav',
        example: []
    };

    handleInputChange = event => {
        const{name, value} = event.target;
        this.setState({
            [name]: value
        });
        console.log("handleInput", value);    
    };

    handleSubmit = event => {
        event.preventDefault();
        this.callApi();
        this.setState({
            word: '',
            wordError: '',

        });    
    }

    checkState  = () => {
        console.log(this.state.word);
    }

    callApi = () => {
        const query = this.state.word;
        const MWD_KEY = process.env.REACT_APP_WD_API_KEY;
        axios.get(`https://www.dictionaryapi.com/api/v3/references/sd2/json/${query}?key=${MWD_KEY}`)
      .then(res => {
        const data = res.data;
        console.log('res.data ', data);
        try {
            const returnedWord = res.data[0].hwi.hw;
            this.setState({ data, returnedWord});
        }   catch(wordError) {
            this.setState({wordError});
            console.log(wordError);
        }            
        try {
            let audio = res.data[0].hwi.prs[0].sound.audio;
            this.setState({audio});
            let audioArr = audio.split("");
            console.log(audioArr);
            let audioPrefix = audioArr.shift();
            console.log(audioPrefix);
            this.setState({audioPrefix});
            console.log('after setState audioPrefix ',this.state.audioPrefix); 
            console.log('after setState', this.state.data, this.state.returnedWord, this.state.audio, this.state.audioPrefix);
        }   catch(audioError) {
            this.setState({audioError});
        }   
        try {
            const example = res.data[0].def[0].sseq;
            console.log(example);
            this.setState({example});
            console.log('after setState example ', this.state.example)
        }   catch(exampleError) {
            this.setState({exampleError});
        }
      })
    }
    
    createAudioUrl = () => {
        let baseUrl = 'https://media.merriam-webster.com/soundc11/';
        let prefix = this.state.audioPrefix;
        let baseAudio = this.state.audio;
        let extension = this.state.extension;
        let audioUrl = baseUrl+prefix+'/'+baseAudio+extension;
        console.log(audioUrl);
        return(audioUrl);
    }

    formatReturnedSense = () => {
        let sense = this.state.example;
        console.log("sense array[0] ", sense);
        sense.map(element => console.log(element));
        sense.forEach(element => {
           console.log(element);
           for(let i=0, j=sense.length; i<j; i++){
                console.log(i);
           }
       });
       
    }

    render () {
        if(this.state.wordError){
            return <>
            <div className="row" id="form-row">
            <div className="col-12" id="form-container">
                <form onSubmit={this.handleSubmit}>
                    <input
                    type="text"
                    value={this.state.word}
                    name="word"
                    onChange={this.handleInputChange}
                    placeholder="Oops...try again"
                    spellCheck={'true'}
                    >
                    </input>
                    <button type="submit">Go Hunt</button>
                </form>
            </div>
        </div>
        <div className="row style-solid-transparent returned-data" id="returned-data">
            <div className="col-6 returned-data" id="word-searched">
                <div className="returned-data-container">
                    <h2>Word Found</h2>
                    <div id="word-container">
                    <ul>
                        "Hmmm, no word was found"
                    </ul> 
                    </div>
                </div>    
            </div>
            <div className="col-6 returned-data" id="pronunciation">
                <div className="returned-data-container">
                    <h2>Pronunciation</h2>
                    <div id="audio-container">
                    <span id="hearing-img"><img src="https://i.ibb.co/kX00dKd/  baseline-hearing-white-24dp.png" alt="listen to the word  pronunciation"></img></span>
                        <audio src="" type="audio" controls></ audio>
                    </div>
                </div>       
            </div>
        </div>
        <div className="row returned-data style-solid-dark">    
            <div className="col-12" id="definitions">
                <h2>Definitions</h2>
                <ul>
                    
                </ul> 
            </div>
        </div>
        </>
        }
        return <>
        <div className="row" id="form-row">
            <div className="col-12" id="form-container">
                <form onSubmit={this.handleSubmit}>
                    <input
                    type="text"
                    value={this.state.word}
                    name="word"
                    onChange={this.handleInputChange}
                    placeholder="type your word"
                    spellCheck={'true'}
                    >
                    </input>
                    <button type="submit">Go Hunt</button>
                </form>
            </div>
        </div>
        <div className="row style-solid-transparent returned-data" id="returned-data">
            <div className="col-6 returned-data" id="word-searched">
                <div className="returned-data-container">
                    <h2>Word Found</h2>
                    <div id="word-container">
                    <ul>
                        {this.state.returnedWord}
                    </ul> 
                    </div>
                </div>    
            </div>
            <div className="col-6 returned-data" id="pronunciation">
                <div className="returned-data-container">
                    <h2>Pronunciation</h2>
                    <div id="audio-container">
                    <span id="hearing-img"><img src="https://i.ibb.co/kX00dKd/  baseline-hearing-white-24dp.png" alt="listen to the word  pronunciation"></img></span>
                        <audio src={this.createAudioUrl()} type="audio" controls></ audio>
                    </div>
                </div>       
            </div>
        </div>
        <div className="row returned-data style-solid-dark">    
            <div className="col-12" id="definitions">
                <h2>Definitions</h2>
                <ul>
                    { this.state.data.map(x => <li key={x.meta.id}>{x.shortdef[0]}</li>)}
                </ul> 
            </div>
        </div>    
            {/* Additional Returned Information to Include Later */}
            {/* <div className="row col-12">
                <div className="row returned-data" id="examples">
                        <h2>Example Sentence</h2>
                        {this.formatReturnedSense()}
                </div>
                <div className="row returned-data" id="more-info">
                    <h2>More Info</h2>
                    <select>
                        <option value="type">What type of word am I ?</option>
                        <option value="history">What is my history ?</option>
                        <option value="tense">My tenses</option>
                    </select>
                </div> 
            </div> */}
        </>;
    }
}

export default ApiQuery