// import React from 'react';
import React, {Component} from 'react';
import Header from './components/Header';
import './App.css';
import ApiQuery from './components/ApiQuery.js';
import ReactGA from 'react-ga';

initializeReactGA();

class App extends Component {
   
render(){

    return (
        <div className="app-container">
          <div className="header-container">
            <Header/>
          </div>
          <div className="main-container">
            <ApiQuery />
          </div>
          <div className="footer-container">
          </div>        
        </div>
        
        
        
    );
}
}

function initializeReactGA() {
  ReactGA.initialize('UA-142109933-2');
  ReactGA.pageview('/homepage'); 
}


export default App;
